<template>
  <label
    :for="name"
    class="relative font-medium"
    :class="[isDisabled ? 'text-gray-300' : 'text-gray-900', variantSize()]"
  >
    <span>
      {{ text }}
    </span>
    <span v-if="isRequired" class="absolute text-cred pl-0.5"> *</span>
  </label>
</template>

<script setup lang="ts">
import { Size } from '@/types/enums'

const props = withDefaults(
  defineProps<{
    isRequired?: boolean
    text: string
    name?: string
    size?: Size
    isDisabled?: boolean
  }>(),
  {
    size: Size.sm,
    name: undefined,
  }
)

function variantSize() {
  switch (props.size) {
    case Size.sm:
      return ['block text-sm']
    case Size.md:
      return ['text-base']
  }
}
</script>
